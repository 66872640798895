import { Outlet, useLoaderData, Form, redirect, useParams, useNavigate } from "react-router-dom";
import { Button, Layout, Input, Space, Menu, ConfigProvider, List, Tag, Flex, Table, TableColumnsType, notification, Select, DatePicker } from "antd";
import type { MenuProps, TableProps } from 'antd';
import { useEffect, useMemo, useRef, useState } from "react";
import { getAllGrievance as search } from "../../service/httpsCalls";
import { LoaderSpinner } from "../../components/common/Loader";
import { FilterButton } from "../../components/common/FilterButton";
import { ColumnDropdown } from '../../components/common/ColumnDropdown';
import debounce from 'lodash.debounce';
import { FilterValue, SorterResult } from "antd/es/table/interface";
import dayjs from "dayjs";

const { Content, Sider, Header } = Layout;
const { Search } = Input;
type MenuItem = Required<MenuProps>['items'][number];
interface CheckedProps {
    item: string;
    checked: boolean;
}

interface SearchParams {
    searchText: string,
    searchType: string,
    pageIndex: any,
    pageSize: any,
    statusId: FilterValue | null,
    dueDateFrom: any,
    dueDateTo: any,
    typeId: FilterValue | null,
    sortField: string | null,
    sortDirection: string | null,
    createdDateFrom: any,
    createdDateTo: any
}
function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}
export async function action() {
    return redirect(`/grievances/create`);
}
export default function GrievancePortal() {
    const { id } = useParams();
    const [grievancesLoading, setGrievancesLoading] = useState(true)
    const navigate = useNavigate();
    const [grievances, setGrievances] = useState([]);
    const [displayedGrievances, setDisplayedGrievances] = useState([]); // State for currently displayed items
    const [api, contextHolder] = notification.useNotification();
    const [pageSize, setPageSize] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [menuVisible, setMenuVisible] = useState(false);
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });
    const [filteredSearchResult, setFilteredSearchResult] = useState([]);
    const [typeFilter, setTypeFilter] = useState<FilterValue | null>();
    const [statusFilter, setStatusFilter] = useState<FilterValue | null>([]);
    const [loading, setLoading] = useState(false)
    const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({ order: 'descend', field: "createdDate" });
    const [searchItem, setSearchItem] = useState('')
    const [searchTypeValue, setSearchTypeValue] = useState('all');
    const [startDate, setStartDate] = useState<string | any>('');
    const [endDate, setEndDate] = useState<string | any>('');
    const [createdAtStart, setCreatedAtStart] = useState<string | any>('');
    const [createdAtEnd, setCreatedAtEnd] = useState<string | any>('');
    //Using these fields to handle Complaint Date filter issue
    const [tempStartDate, setTempStartDate] = useState<string | any>('');
    const [tempEndDate, setTempEndDate] = useState<string | any>('');
    const [tempCreatedStart, setTempCreatedStart] = useState<string | any>('');
    const [tempCreatedEnd, setTempCreatedEnd] = useState<string | any>('');
    const [dateUpdated, setDateUpdated] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);
    const { RangePicker } = DatePicker;
    const searchTypeOptions = [
        { label: 'All', value: 'all' },
        { label: 'Case Number', value: 'case-number' },
        { label: 'Trip Number', value: 'trip-number' },
        { label: 'Member Id', value: 'member-id' },
        { label: 'Member Name', value: 'member-name' },
        { label: 'Created By', value: 'created-by' }
    ];

    const columns: TableColumnsType<any> = [
        {
            title: 'Trip Id',
            dataIndex: 'tripId',
            sorter: true,
            filterOnClose: false,
            render: (value, record) => {
                return (
                    <a
                        onClick={(event) => handleNavigation(record)}
                        target="_blank"
                    >
                        {value}
                    </a>
                );
            }
        },
        
        {
            title: 'Member Name',
            dataIndex: 'memberName',
            sorter: true,
            filterOnClose: false
        },
        {
            title: 'Case Id',
            dataIndex: 'clientCaseId',
            sorter: true,
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate',
            render: (text) => (text ? dayjs(text, 'DD-MM-YYYY').format('MM/DD/YYYY'):''),
            sorter: true,
            filterOnClose: false,
            filtered: startDate && endDate,
            onFilterDropdownOpenChange: (visible) => handleDateFilterOpen(visible),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <RangePicker
                    allowClear={true}
                    format={["MM/DD/YYYY"]}
                    onChange={(dates: any) => {
                        if (dates) {
                            setTempStartDate(dates[0]);
                            setTempEndDate(dates[1]);
                        }
                        else {
                            setTempStartDate('');
                            setTempEndDate('');
                        }
                    }}
                    value={[tempStartDate, tempEndDate]}
                />
                <Flex justify='flex-end' style={{ marginTop: 8 }}>

                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: true });
                            setCurrentPage(1);
                            onDateFilterChanged('dueDate');
                        }}
                    >
                        OK
                    </Button>

                </Flex>
            </div>
        },
        {
            title: 'Type',
            dataIndex: 'type',
            sorter: true,
            filterOnClose: false,
            filteredValue: typeFilter || null,
            filters: [
                { value: 2, text: "Standard" },
                { value: 3, text: "DMHC" },
                { value: 5, text: "Urgent" }
            ],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: true,
            filterOnClose: false,
            filteredValue: statusFilter || null,
            filters: [
                { value: 1, text: "Open" },
                { value: 6, text: "Retracted" },
                { value: 7, text: "Pending" },
                { value: 8, text: "In Progress" },
                { value: 9, text: "Internal Review" },
                { value: 10, text: "Client Review" },
                { value: 11, text: "Resolved" },
                { value: 12, text: "Client Denied" }
            ]
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            sorter: true
        },
        {
            title: 'Created On',
            dataIndex: 'createdDate',
            render: (text) => (text ? dayjs(text).format('MM/DD/YYYY') : ''),
            filterOnClose: false,
            filtered: createdAtStart && createdAtEnd,
            onFilterDropdownOpenChange: (visible) => handleCreatedDateFilterOpen(visible),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <RangePicker
                    allowClear={true}
                    format={["MM/DD/YYYY"]}
                    onChange={(dates: any) => {
                        if (dates) {
                            setTempCreatedStart(dates[0]);
                            setTempCreatedEnd(dates[1]);
                        }
                        else {
                            setTempCreatedStart('');
                            setTempCreatedEnd('');
                        }
                    }}
                    value={[tempCreatedStart, tempCreatedEnd]}
                />
                <Flex justify='flex-end' style={{ marginTop: 8 }}>

                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: true });
                            setCurrentPage(1);
                            onDateFilterChanged("createdAt");
                        }}
                    >
                        OK
                    </Button>

                </Flex>
            </div>,
            sorter: true,
            defaultSortOrder: "descend"
        },
    ];

    const [isChecked, setChecked] = useState<CheckedProps[]>(
        columns.map((entry: any) => ({ item: entry.title, checked: !entry.hidden }))
    );
    function handleNavigation(rowData: any) {
        navigate(`/grievances/${rowData.id}${window?.location?.search}`)
    }
    const handleNewGrievanceClick = (e) => {
        e.preventDefault();
        navigate(`/grievances/create${window?.location?.search}`); // Adjust the path as needed
    };

    useEffect(() => {
        if (dateUpdated && startDate == tempStartDate && endDate == tempEndDate && createdAtEnd == tempCreatedEnd && createdAtStart == tempCreatedStart) {
            currentPage == 1 ? debouncedSearch() : setCurrentPage(1);
        }
    }, [startDate, endDate, createdAtEnd, createdAtStart, dateUpdated]);

    const onDateFilterChanged = (filterType: string) => {
        setDateUpdated(true);
        if (filterType == 'dueDate') {
            setStartDate(tempStartDate);
            setEndDate(tempEndDate);
        } else if (filterType == 'createdAt') {
            setCreatedAtEnd(tempCreatedEnd);
            setCreatedAtStart(tempCreatedStart);
        }
    }

    const handleDateFilterOpen = (visible: boolean) => {
        if (visible) {
            setTempStartDate(startDate);
            setTempEndDate(endDate);
        }
    }

    const handleCreatedDateFilterOpen = (visible: boolean) => {
        if (visible) {
            setTempCreatedStart(createdAtStart);
            setTempCreatedEnd(createdAtEnd);
        }
    }    

    useEffect(() => {
        if (listRef.current) {
            const newPageSize = Math.floor(listRef.current.clientHeight / 65);
            setPageSize(newPageSize);
        }
        debouncedSearch();
    }, []);

    // This effect updates the list of grievances to display based on the current page
    // useEffect(() => {
    //     const startIndex = (currentPage - 1) * pageSize;
    //     const endIndex = startIndex + pageSize;
    //     const paginatedGrievances = initialGrievances.slice(startIndex, endIndex);
    //     setGrievances(paginatedGrievances);
    // }, [currentPage, initialGrievances, pageSize]);

    function handleSearch(e: any) {
        const searchText = e.key !== "Enter" ? e.target.value : searchItem;
        setSearchItem(e.target.value);
        currentPage == 1 ? debouncedSearch() : setCurrentPage(1);
    }
    function onChangeSearchType(value: string) {
        setSearchTypeValue(value)
    }

    const handlePageChange = (page: any, pageSize: any) => {
        setCurrentPage(page);
        // Optionally update the page size if it can change
        setPageSize(pageSize);
    };

    useEffect(() => {
        console.log(currentPage);
        if (pageSize != 0)
            debouncedSearch();
    }, [currentPage, pageSize, sortedInfo]);

    const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setCurrentPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);
        setTotalPages(pagination.total ?? 100);
        setStatusFilter(filters.status);
        setTypeFilter(filters.type);
        setSortedInfo(sorter as SorterResult<any>);
        console.log(sorter)
    };

    const useDebounce = (callback: any) => {
        const ref = useRef<any>();

        useEffect(() => {
            ref.current = callback;
        }, [callback]);

        const debouncedCallback = useMemo(() => {
            const func = () => {
                ref.current?.();
            };

            return debounce(func, 1000);
        }, []);

        return debouncedCallback;
    };

    const debouncedSearch = useDebounce(() => {
        let data = {
            searchText: searchItem,
            searchType: searchItem?searchTypeValue:'',
            pageIndex: currentPage-1,
            pageSize: pageSize,
            statusId: statusFilter?.length == 0 ? null : statusFilter,
            dueDateFrom: startDate?.length === 0 ? null : dayjs(startDate).format('YYYY-MM-DDT00:00:00'),
            dueDateTo: endDate?.length === 0 ? null : dayjs(endDate).format('YYYY-MM-DDT23:59:59'),
            typeId: typeFilter?.length == 0 ? null : typeFilter,
            againstTypeId: null,
            sourceTypeId: null,
            clientIds: null,
            sortField: sortedInfo.field,
            sortDirection: sortedInfo.order,
            createdDateFrom: createdAtStart?.length === 0 ? null : dayjs(createdAtStart).format('YYYY-MM-DDT00:00:00'),
            createdDateTo: createdAtEnd?.length === 0 ? null : dayjs(createdAtEnd).format('YYYY-MM-DDT23:59:59')
        }
        getGrievanceList(data);
    });

    // Extract the API call logic into a function
    const getGrievanceList = ({ searchText, searchType, pageIndex, pageSize, statusId, dueDateFrom, dueDateTo, typeId, sortField, sortDirection, createdDateFrom, createdDateTo }: SearchParams) => {
        console.log("executed");
        setGrievances([]);
    setLoading(true);
        search({
            searchText,
            searchType,
            pageIndex,
            pageSize,
            statusId,
            dueDateFrom,
            dueDateTo,
            typeId,
            sortField,
            sortDirection,
            createdDateFrom,
            createdDateTo
        }, (res: any) => {
            if (res !== 'error') {
                res.items.forEach((e: any) => {
                    if (e.references?.length) {
                        e['tripId'] = e.references.length == 1 && e.references[0].tripId != null ? ('CTC-' + e.references[0].tripId) : 'Multiple Trips';
                        e['memberName'] = e.references[0].memberName;
                    } else {
                        e['tripId'] = '';
                        e['memberName'] = '';
                    }
                });
                setGrievances(res.items)
                setTotalPages(Math.ceil(res.totalRecords / (pageSize / pageSize)));
            } else {
                setGrievances([])
        }
            setLoading(false);

        });
    };

    return (
        <ConfigProvider theme={{
            components: {
                Layout: { headerBg: "#eff1f5", bodyBg: "#eff1f5" }
            }
        }} >
            {contextHolder}
            <Layout style={{ borderWidth: 0, borderTopWidth: 1, borderColor: "#d9d9d9", borderStyle: "solid", height: "100%" }}>
                <Layout style={{ minHeight: "calc(100vh - 165px)", padding: "2rem" }}>
                    <Content
                        ref={listRef}
                    >
                        <Flex justify='end' style={{ marginBottom: "2rem" }} gap='small'>
                            <Input
                                onChange={handleSearch}
                                style={{ width: "35vw" }}
                                // size={"large"}
                                allowClear
                                addonBefore={
                                    <Select
                                        style={{ width: "120px" }}
                                        options={searchTypeOptions}
                                        onSelect={onChangeSearchType}
                                        value={searchTypeValue} />}
                            />
                            <Space>
                                <Button onClick={handleNewGrievanceClick} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', marginLeft: '5px', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                                    New
                                </Button>
                            </Space>
                        </Flex>
                        <ColumnDropdown columns={columns} menuVisible={menuVisible} position={position} isChecked={isChecked} setChecked={setChecked} />
                        <Table
                            style={{ overflowY: "auto", height: "calc(100vh - 164px)" }}
                            dataSource={grievances}
                            pagination={{
                                defaultCurrent: 1,
                                current: currentPage,
                                pageSize: pageSize,
                                total: totalPages,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                showSizeChanger: true,
                            }}
                            columns={columns}
                            onChange={handleTableChange}
                            rowKey="id"
                            loading={{ indicator: <LoaderSpinner size={24} topMargin="10px" />, spinning: loading }}
                            onHeaderRow={(columns, index) => {
                                return {
                                    onContextMenu: (event) => {
                                        event.preventDefault();
                                        if (!menuVisible) {
                                            document.addEventListener(`click`, function onClickOutside() {
                                                setMenuVisible(false);
                                                document.removeEventListener(`click`, onClickOutside);
                                            });
                                        }
                                        setMenuVisible(true);
                                        setPosition({ x: event.clientX, y: event.clientY });
                                    }
                                };
                            }}
                        />

                    </Content>

                </Layout>
            </Layout>
            {/*<Layout>*/}
            {/*    <Sider width="30%" theme="light" style={{ maxHeight: "95vh", position: 'fixed' }}>*/}
            {/*        <Flex vertical={true} ref={listRef}>*/}
            {/*            <Flex justify="space-between" gap="middle" style={{ padding: "1rem" }}>*/}
            {/*                <Form id="search-form" role="search" style={{ width: "100%" }}>*/}
            {/*                    <Search*/}
            {/*                        allowClear*/}
            {/*                        id="q"*/}
            {/*                        aria-label="Search grievances"*/}
            {/*                        placeholder="Search"*/}
            {/*                        type="search"*/}
            {/*                        name="q"*/}
            {/*                        defaultValue={q}*/}
            {/*                        value={query}*/}
            {/*                        onChange={(e) => {*/}
            {/*                            setQuery(e.target.value);*/}
            {/*                        }}*/}
            {/*                    />*/}
            {/*                </Form>*/}
            {/*                <Form method="post">*/}
            {/*                    <Button type="default" onClick={handleNewGrievanceClick}>New</Button>*/}
            {/*                </Form>*/}

            {/*            </Flex>*/}
            {/*            <Space style={{ paddingLeft: "1rem", paddingRight: "1rem", paddingBottom: "1rem", width: "100%" }}>*/}
            {/*                <FilterButton*/}
            {/*                    filter="Type"*/}
            {/*                    filterItems={["Standard",*/}
            {/*                        "Urgent",*/}
            {/*                        "DMHC",*/}
            {/*                        "Accident/Injury/Incident",*/}
            {/*                        "Crisis Alert"*/}
            {/*                    ]}*/}
            {/*                />*/}
            {/*                <FilterButton*/}
            {/*                    filter="Status"*/}
            {/*                    filterItems={["Open", "Retracted", 'Pending', 'In Progress', 'Internal Review', 'Client Review', 'Resolved', 'Client Denied']}*/}
            {/*                />*/}
            {/*            </Space>*/}
            {/*            {grievancesLoading && <LoaderSpinner size={24} topMargin="10px" />}*/}
            {/*            {grievances?.length ? <List*/}
            {/*                style={{ overflowY: "auto", height: "calc(100vh - 164px)" }}*/}
            {/*                id="grievanceList"*/}
            {/*                dataSource={displayedGrievances}*/}
            {/*                pagination={{*/}
            {/*                    current: currentPage,*/}
            {/*                    pageSize: pageSize,*/}
            {/*                    total: grievances.length,*/}
            {/*                    onChange: handlePageChange,*/}
            {/*                    showSizeChanger: true,*/}
            {/*                    position: "bottom", align: "center",*/}
            {/*                }}*/}
            {/*                renderItem={(item: any, index) => (*/}

            {/*                    <List.Item style={{ padding: "1rem" }}*/}
            {/*                        aria-selected={ window.location.pathname.includes(item.id) }*/}
            {/*                        extra={<Space direction="vertical" align="end">*/}
            {/*                            <Tag style={{ marginRight: 0 }} color="blue">{item.status}</Tag>*/}
            {/*                            <small>{item.dueDate ? item.type + " - " + "Due " + item.dueDate : item.type + "- No Due Date"}</small>*/}
            {/*                        </Space>}*/}
            {/*                        onClick={() => {*/}
            {/*                            if(!window.location.pathname.includes(item.id))*/}
            {/*                                navigate(`${item.id}${window?.location?.search}`)*/}
            {/*                        }                                    }>*/}
            {/*                        <List.Item.Meta*/}
            {/*                            title={item.clientCaseId ?? "Case Id"}*/}
            {/*                            description={item.references[0].memberName + (item.references.length == 1 && item.references[0].tripId != null ? ` - CTC-${item.references[0].tripId}` : ``) + (item.references.length > 1 ? ' - Multiple Trips' : '') }*/}
            {/*                        />*/}
            {/*                    </List.Item>*/}
            {/*                )}*/}
            {/*            /> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: "calc(100vh - 164px)" }}>No results</div>}*/}
            {/*        </Flex>*/}
            {/*    </Sider>*/}
            {/*    <Content style={{ marginLeft: "30vw", paddingLeft: ".5rem" }}>*/}
            {/*        <Outlet />*/}
            {/*    </Content>*/}
            {/*</Layout>*/}
        </ConfigProvider>
    );
}

