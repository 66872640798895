import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, TableColumnsType } from "antd";
import dayjs from "dayjs";

export const AuditTableColumns: TableColumnsType<any> = [
    {
        title: 'Date/Time',
        dataIndex: 'date',
        render: (text) => dayjs(text).format('MM/DD/YYYY HH:mm A'),
        sorter: true
    },
    {
        title: 'User Id',
        dataIndex: 'userName',
        sorter: true
    },
    {
        title: 'Field',
        dataIndex: 'fieldName',
        sorter: true
    },
    {
        title: 'Original Value',
        dataIndex: 'oldValue'
    },
    {
        title: 'New value',
        dataIndex: 'newValue'
    },
    {
        title: 'Note Text',
        dataIndex: 'note'
    }
];

export const DownloadFileColumns: TableColumnsType<any> = [
    {
        title: 'Date/Time',
        dataIndex: 'date',
        render: (text) => dayjs(text).format('MM/DD/YYYY HH:mm A'),
        sorter: true
    },
    {
        title: 'System User',
        dataIndex: 'userName',
        sorter: true
    },
    {
        title: 'File Name',
        dataIndex: 'fileName',
        sorter: true
    }
];

export const UploadFileColumns: TableColumnsType<any> = [
    {
        title: 'Date/Time',
        dataIndex: 'date',
        render: (text) => dayjs(text).format('MM/DD/YYYY HH:mm A'),
        sorter: true
    },
    {
        title: 'System User',
        dataIndex: 'userName',
        sorter: true
    },
    {
        title: 'File Name',
        dataIndex: 'fileName',
        sorter: true
    },
    {
        title: 'Note',
        dataIndex: 'note'
    }
];

export const DownloadDataColumns: TableColumnsType<any> = [
    {
        title: 'Date/Time',
        dataIndex: 'date',
        render: (text) => dayjs(text).format('MM/DD/YYYY HH:mm A'),
        sorter: true
    },
    {
        title: 'System User',
        dataIndex: 'userName',
        sorter: true
    },
    {
        title: 'File Name',
        dataIndex: 'fileName',
        sorter: true
    },
    {
        title: 'Status',
        dataIndex: 'status',
        sorter: true
    }
];

export const MakeCorrectionColumns: TableColumnsType<any> = [
    {
        title: 'Date Of Service',
        dataIndex: 'dateOfService',
        render: (text) => dayjs(text).format('MM/DD/YYYY'),
        sorter: true
    },
    {
        title: 'CTC Trip Id',
        dataIndex: 'ctcTripId',
        sorter: true
    },
    {
        title: 'Member Id',
        dataIndex: 'memberId',
        sorter: true
    },
    {
        title: 'Status',
        dataIndex: 'tripLegStatus',
        sorter: true
    },
    {
        title: 'Billed Amount',
        dataIndex: 'billedAmount',
        sorter: true
    },
    {
        title: 'Billable',
        dataIndex: 'billable',
        sorter: true
    },
    {
        title: 'Level Of Service',
        dataIndex: 'levelOfService',
        sorter: true
    }
];

const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => { setSelectedKeys([]); clearFilters(); confirm(); }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : "",
});

export const createTableConfig = (config: any) => {
    let columns: any = [];
    config.forEach((x) => {
        if (!x.hidden) {
            if (!(x.dataType && x.dataType == 'date')) {
                let colConfig = {
                        title: x.title,
                        dataIndex: x.dataIndex,
                    hidden: x.hidden ?? false,
                        sorter: x.sorter,
                        width: 120,
                        fixed: x.fixed ?? false,
                        onCell: (record) => ({
                        style: { background: record.oldData && ((record.oldData[x.dataIndex] || record[x.dataIndex]) != '') && record.oldData[x.dataIndex] != record[x.dataIndex] ? "#66B966" : "" }
                        }),
                        render: (text, record) => (
                            text && text.length > 20 ?
                                <span title={text}>{text.toString().substring(0, 20) + '...'}</span> : text
                        )
                    }
                if (x.filter) {
                    colConfig = { ...colConfig, ...getColumnSearchProps(x.title, x.dataIndex) }
                }
                columns.push(
                    colConfig
                )
            } else {
                if (x.dataType == 'date') {
                    let colConfig = {
                            title: x.title,
                            dataIndex: x.dataIndex,
                        hidden: x.hidden ?? false,
                            sorter: x.sorter,
                            width: 120,
                            fixed: x.fixed ?? false,
                            onCell: (record) => ({
                                style: { background: record.oldData && record.oldData[x.dataIndex] != record[x.dataIndex] ? "#66B966" : "" }
                            }),
                            render: (text) => dayjs(text).format(x.format)
                        }
                    if (x.filter) {
                        colConfig = { ...colConfig, ...getColumnSearchProps(x.title, x.dataIndex) }
                    }
                    columns.push(
                        colConfig
                    )
                }
            }
        }
    })
    return columns;
}