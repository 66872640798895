import { Button, Checkbox, Col, DatePicker, Flex, Input, Row, Select, Space, Table, Tabs, Modal, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { getAddInvoiceDetailsByType, saveInvoice as save, getInvoice, getInvoiceAuditHistory, deleteInvoice as deleteI, changeInvoiceStatus, getStatusById, getUploadedFiles, downloadRawDataFile, uploadFile, getSubmittedFiles, downloadUploadedFile, getRawDataFiles, generateRawDataFile, downloadSubmittedFile, getInvoiceActions } from '../../service/httpsCalls';
import dayjs, { Dayjs } from "dayjs";
import type { DatePickerProps, GetProps, TableColumnsType, TableProps } from 'antd';
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
import moment from 'moment';
import Card from 'antd/es/card/Card';
import { SorterResult } from 'antd/es/table/interface';
import { ColumnDropdown } from '../../components/common/ColumnDropdown';
import { LoaderSpinner } from '../../components/common/Loader';
import TextArea from 'antd/es/input/TextArea';
import { AuditTableColumns, DownloadDataColumns, DownloadFileColumns, UploadFileColumns } from './tableConfig';
import { DownloadOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import "./billing.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const { confirm } = Modal;

interface ClientData {
    id: number,
    name: string
}

interface FilterData extends ClientData {
    clientId: number
}

interface FormFields {
    invoiceClient: any | null,
    clientName?: string,
    invoiceNumber: string | null,
    startDate: string | any,
    endDate: string | any,
    invoiceType: string | null,
    invoiceTypeName?: string,
    invoiceDate: string | any,
    includePrevious: boolean,
    scheduleDateTime: string | any,
    status: string | any
}

const getYearMonth = (date: Dayjs) => date.year() * 12 + date.month();

interface PaginationObject {
    totalPages: number,
    loading: boolean,
    pageSize: number,
    currentPage: number,
    sortInfo: SorterResult<any>,
    menuVisible: boolean
}

interface AdditionInfo {
    caption: string,
    value: string
}
export default function CreateInvoice({ clientList, invoiceTypeLookup, selectedInvoice, action, filterData, modal, userAccess, statusList, parentCallback}) {
    const [form, setForm] = useState<FormFields>({
        invoiceClient: filterData?.filterClient ??null,
        invoiceNumber: '',
        startDate: null,
        endDate: '',
        invoiceType: null,
        invoiceDate: null,
        includePrevious: false,
        scheduleDateTime: '',
        status: ''
    });
    const [globalAPICalled, setGlobalAPICalled] = useState(false);
    const [invoiceTypes, setInvoiceTypes] = useState<FilterData[]>([]);
    const [disabledDates, setDisabledDates] = useState<number[]>([]);
    const [rangeLimit, setRangeLimit] = useState<number>(0);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [editPermissions, setEditPermissions] = useState<any | null>(null)
    const [auditHistory, setAuditHistory] = useState([]);
    const [downloadFiles, setDownloadFiles] = useState<any>([]);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [generatedData, setGeneratedData] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState<AdditionInfo[]>([]);
    const [fileUploadForm, setFileUploadForm] = useState({
        note: '',
        file: null,
        show: false,
        loader: false,
        fileList:[]
    })
    const [loader, setLoader] = useState({
        save: false,
        status: false,
        genarate: false,
        delete: false,
        submitFile: false,
        generateData: false
    });

    const [loading, setLoading] = useState(false);
    const [statusForm, setStatusForm] = useState({
        note: '',
        status: '',
        visible: false,
        statusList:[]
    });
    //pagination object
    let paginationObject: PaginationObject = {
        totalPages: 0,
        loading: false,
        pageSize: 5,
        currentPage: 1,
        sortInfo: {},
        menuVisible: false
    }

    const [downloadDataTable, setDownloadDataTable] = useState(paginationObject);
    const [auditTable, setAuditTable] = useState(paginationObject);
    const [downloadFileTable, setDownloadFileTable] = useState(paginationObject);
    const [uploadFileTable, setUploadFileTable] = useState(paginationObject);
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });
    const [invoiceInfo, setInvoiceInfo] = useState<any | null>(null);
    const auditColumns = AuditTableColumns;
    const downloadFileColumns = DownloadFileColumns;
    const downloadDataColumns = DownloadDataColumns.concat([
        {
            title: 'Download',
            dataIndex: 'fileName',
            render: (text, record) => 
                record.status == 'Pending' || record.status == 'In Progress' ? '' :
                <Button onClick={() => downloadSelectedDataFile(record)} loading={record.downloading} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                    <DownloadOutlined />
                </Button>
            
        }
    ])
    const uploadFileColumns = UploadFileColumns.concat([
        {
            title: 'Download',
            dataIndex: 'fileName',
            render: (text, record) => (
                <Button onClick={() => downloadSelectedFile(record)} loading={record.downloading} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                    <DownloadOutlined />
                </Button>
            )
        }
    ]);
    function validateModal() {
        var isValid = true;
        var errorMessage = '';
        setModalSubmitted(true);
        if (!(form.invoiceClient && form.invoiceType && form.invoiceNumber && form.startDate && form.endDate && form.invoiceDate)) {
            isValid = false;
            errorMessage = "Please fill required details"
        }
        return { isValid, errorMessage };
    }

    function setModalData() {
        if (modal && !form.invoiceClient && filterData?.filterClient) {
            clientSelected(filterData?.filterClient);
            filterData?.filterType && getInvoiceDetails(filterData?.filterType, filterData?.filterClient);
        } else if (modal && form.invoiceClient && form.invoiceClient == filterData?.filterClient && !form.invoiceType) {
            setInvoiceTypes(invoiceTypeLookup.filter((x) => x.clientId == form.invoiceClient));
            filterData?.filterType && getInvoiceDetails(filterData?.filterType);
        }
    }

    function cancelModal(e?:any) {
        clearModalFields();
        selectedInvoice = null;
        setAuditHistory([])
        setModalSubmitted(false);
        setEditPermissions(null);
        setInvoiceInfo(null);
        setAdditionalInfo([]);
        setLoader({
            save: false,
            status: false,
            genarate: false,
            delete: false,
            submitFile: false,
            generateData: false
        })
        setStatusForm({
            note: '',
            status: '',
            visible: false,
            statusList: []
        })
        modal = false;
        setAuditTable(paginationObject);
        setDownloadFileTable(paginationObject);
        setUploadFileTable(paginationObject);
        setDownloadDataTable(paginationObject);
        setFileUploadForm({
            note: '',
            file: null,
            show: false,
            loader: false,
            fileList:[]
        })
        if (e) {
            parentCallback({
                isCancel: true
            })
        }
    }

    function confirmGenerate() {
        confirm({
            title: 'Do you want to generate this invoice?',
            cancelText: "No",
            okText: "Yes",
            onOk() {
                saveInvoice(true)
            },
            onCancel() { },
        });
    }
    function saveInvoice(generateInvoice: boolean = false) {
        if (validateModal().isValid) {
            setLoader({
                ...loader,
                save: !generateInvoice,
                genarate: generateInvoice
            })
            save({
                "id": selectedInvoice?.id ?? null,
                "clientId": form.invoiceClient,
                "invoiceTypeId": form.invoiceType,
                "invoiceDate": dayjs(form.invoiceDate).format('YYYY-MM-DD'),
                "invoiceNumber": form.invoiceNumber,
                "fromDos": dayjs(form.startDate).format('YYYY-MM-DD'),
                "toDos": dayjs(form.endDate).format('YYYY-MM-DD'),
                "includePreviousNonBillable": form.includePrevious,
                "scheduleDateTime": form.scheduleDateTime && form.scheduleDateTime != '' ? dayjs(form.scheduleDateTime).format('YYYY-MM-DDTHH:mm:ss') : null,
                "isSaveAndGenerate": generateInvoice
            }, (res) => {
                setLoader({
                    ...loader,
                    save: false,
                    genarate: false
                })
                if (res?.hasError) {
                    const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                    parentCallback({
                        isInvalid: true,
                        errorMessage: errorMsg
                    })
                } else {
                    cancelModal();
                    parentCallback({
                        isInvalid: false,
                        clientId: form.invoiceClient,
                        invoiceTypeId: form.invoiceType,
                        message: 'Invoice saved successfully!'
                    })
                }
            })
        } else {
            parentCallback({
                isInvalid: true,
                errorMessage: validateModal().errorMessage
            })
        }
    }

    function getUploadFileData() {
        setUploadFileTable({
            ...uploadFileTable,
            loading: true
        })
        setUploadFiles([]);
        getUploadedFiles({
            pageIndex: uploadFileTable.currentPage,
            pageSize: uploadFileTable?.pageSize,
            sortField: uploadFileTable.sortInfo.order ? uploadFileTable.sortInfo.field : null,
            sortDescending: uploadFileTable.sortInfo.order == 'descend',
            id: selectedInvoice?.id
        }, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!';
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
                setUploadFileTable({
                    ...uploadFileTable,
                    loading: false,
                    totalPages: 1
                });
            } else {
                setUploadFiles(res.items);
                setUploadFileTable({
                    ...uploadFileTable,
                    loading: false,
                    totalPages: Math.ceil(res.totalRecords / (uploadFileTable.pageSize / uploadFileTable.pageSize))
                })
            }
        })
    }

    function getDownloadFileData() {
        setDownloadDataTable({
            ...downloadDataTable,
            loading: true
        })
        setUploadFiles([]);
        getRawDataFiles({
            pageIndex: downloadDataTable.currentPage,
            pageSize: downloadDataTable?.pageSize,
            sortField: downloadDataTable.sortInfo.order ? downloadDataTable.sortInfo.field : null,
            sortDescending: downloadDataTable.sortInfo.order == 'descend',
            id: selectedInvoice?.id
        }, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!';
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
                setDownloadDataTable({
                    ...downloadDataTable,
                    loading: false,
                    totalPages: 1
                });
            } else {
                setGeneratedData(res.items);
                setDownloadDataTable({
                    ...downloadDataTable,
                    loading: false,
                    totalPages: Math.ceil(res.totalRecords / (downloadDataTable.pageSize / downloadDataTable.pageSize))
                })
            }
        })
    }

    function getSubmittedFileData() {
        setDownloadFileTable({
            ...downloadFileTable,
            loading: true
        })
        setUploadFiles([]);
        getSubmittedFiles({
            pageIndex: downloadFileTable.currentPage,
            pageSize: downloadFileTable?.pageSize,
            sortField: downloadFileTable.sortInfo.order ? downloadFileTable.sortInfo.field : null,
            sortDescending: downloadFileTable.sortInfo.order == 'descend',
            id: selectedInvoice?.id
        }, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!';
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
                setDownloadFileTable({
                    ...downloadFileTable,
                    loading: false,
                    totalPages: 1
                });
            } else {
                setDownloadFiles(res.items);
                setDownloadFileTable({
                    ...downloadFileTable,
                    loading: false,
                    totalPages: Math.ceil(res.totalRecords / (downloadFileTable.pageSize / downloadFileTable.pageSize))
                })
            }
        })
    }

    function getAuditHistory() {
        setAuditTable({
            ...auditTable,
            loading: true
        })
        setAuditHistory([]);
        getInvoiceAuditHistory({
            pageIndex: auditTable.currentPage,
            pageSize: auditTable?.pageSize,
            sortField: auditTable.sortInfo.order ? auditTable.sortInfo.field : null,
            sortDescending: auditTable.sortInfo.order == 'descend',
            id: selectedInvoice?.id
        }, (res)=>{
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!';
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
                setAuditTable({
                    ...auditTable,
                    loading: false,
                    totalPages: 1
                });
            } else {
                setAuditHistory(res.items);
                setAuditTable({
                    ...auditTable,
                    loading: false,
                    totalPages: Math.ceil(res.totalRecords / (auditTable.pageSize / auditTable.pageSize))
                })
            }
        })
    }

    function getInvoicePermissions() {
        getInvoiceActions(selectedInvoice.id, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
            } else {
                
                parentCallback({
                    canChangeTab: true,
                    makeCorrection: {
                        invoiceInfo: { ...res.info, id: selectedInvoice.id },
                        canMakeCorrection: res.actions.canMakeCorrection
                    }
                });
                setEditPermissions({
                    ...editPermissions,
                    ...res
                });
            }
        })
    }

    function getInvoiceData() {
        setLoading(true);
        getInvoice(selectedInvoice.id, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
            } else {
                setLoading(false);
                setInvoiceInfo(res.info);
                parentCallback({
                    canChangeTab: true,
                    makeCorrection: {
                        invoiceInfo: { ...res.info, id: selectedInvoice.id },
                        canMakeCorrection: res.actions.canMakeCorrection
                    }
                });
                setInvoiceTypes(invoiceTypeLookup.filter((x) => x.clientId == res?.info?.clientId));
                
                setEditPermissions({
                    ...res.actions,
                    isDownloadTabVisible: res?.info?.isDownloadTabVisible ?? false,

                });
                setAdditionalInfo(res?.additionalInfo);

                if (res?.info?.clientId == 10 && res?.info?.invoiceTypeId == 1) {
                    setRangeLimit(res?.info?.noOfDaysAllowedForDosRange);
                } else {
                    setRangeLimit(0)
                }

                if (res?.info?.clientId == 10 && res?.info?.invoiceTypeId == 1 && res.info?.daysNotAllowedForInvoiceDate) {
                    setDisabledDates(res?.info?.daysNotAllowedForInvoiceDate?.split(',').map((p) => { return parseInt(p) }));
                } else {
                    setDisabledDates([]);
                }
            }
        })
    }

    useEffect(() => {
        if (clientList.length && invoiceTypes.length && invoiceInfo) {
            setForm({
                invoiceDate: dayjs(invoiceInfo.invoiceDate),
                invoiceNumber: invoiceInfo.invoiceNumber,
                startDate: dayjs(invoiceInfo.fromDos),
                endDate: dayjs(invoiceInfo.toDos),
                includePrevious: invoiceInfo.includePreviousNonBillable,
                scheduleDateTime: invoiceInfo.scheduleDateTime ? dayjs(invoiceInfo.scheduleDateTime) : null,
                invoiceClient: invoiceInfo.clientId.toString(),
                invoiceType: invoiceInfo.invoiceTypeId.toString(),
                status: invoiceInfo.status,
                clientName: invoiceInfo.clientName,
                invoiceTypeName: invoiceInfo.invoiceType
            });
        }
    }, [clientList, invoiceTypes, invoiceInfo])

    useEffect(() => {
        if (action == 'edit' && modal == true) {
            if (!globalAPICalled && selectedInvoice?.id) {
                setGlobalAPICalled(true);
            }
        } else if (action == 'cancel') {
            cancelModal();
        }
        if (action == 'add' && modal == true) {
            setModalData();
        }
    }, [action]);

    useEffect(() => {
        if (globalAPICalled) {
            getAuditHistory();
            getInvoiceData();
            getUploadFileData();
            getDownloadFileData();
            getSubmittedFileData();
        }
    }, [globalAPICalled]);

    function clearModalFields() {
        setRangeLimit(0);
        setForm({
            ...form,
            invoiceDate: null,
            invoiceNumber: null,
            startDate: null,
            endDate: null,
            includePrevious: false,
            scheduleDateTime: null,
            invoiceClient: null,
            invoiceType: null,
            status:''
        });
        setInvoiceTypes([]);
    }
    function clientSelected(e) {
        if (e) {
            setForm({
                ...form,
                invoiceClient: e
            })
            setInvoiceTypes(invoiceTypeLookup.filter((x) => x.clientId == e));
        }
    }

    function getInvoiceDetails(e, c?: any) {
        if (!e) { return }
        setLoading(true);
        getAddInvoiceDetailsByType({
            clientId: form.invoiceClient ?? c,
            invoiceTypeId:e
        }, (res) => {
            setLoading(false);
            if (res?.hasError) {
                setForm({
                    ...form,
                    invoiceType: e,
                    invoiceClient: form.invoiceClient ?? c
                });
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
            } else if (res) {
                setForm({
                    ...form,
                    invoiceDate: dayjs(res.invoiceDate),
                    invoiceNumber: res.invoiceNumber,
                    startDate: dayjs(res.fromDos),
                    endDate: dayjs(res.toDos),
                    includePrevious: res.includePreviousNonBillable,
                    invoiceType: e,
                    invoiceClient: form.invoiceClient ?? c
                });
                if (form.invoiceClient == 10 && e == 1) {
                    setRangeLimit(res.noOfDaysAllowedForDosRange);
                } else {
                    setRangeLimit(0)
                }
                if (form.invoiceClient == 10 && e == 1 && res.daysNotAllowedForInvoiceDate) {
                    setDisabledDates(res.daysNotAllowedForInvoiceDate?.split(',').map((p) => { return parseInt(p) }));
                } else {
                    setDisabledDates([]);
                }
            }
        })
    }

    const disableInvoiceDates: DatePickerProps['disabledDate']= (current) => {
        if (disabledDates.length == 0) {
            return false;
        }
        var currentDay = dayjs(current).day();
        return disabledDates.includes((currentDay+1))
    }

    const disabledRangeDate: RangePickerProps['disabledDate'] = (current, { from, type }) => {
        if (from) {
            if (rangeLimit == 0) {
                return false;
            }
            var range = rangeLimit;
            if (dayjs(from).day() == 6) {

                range = (rangeLimit + 2);
            }

            const minDate = from.subtract(range, 'days');
            const maxDate = from;

            switch (type) {
                case 'year':
                    return current.year() < minDate.year() || current.year() > maxDate.year();

                case 'month':
                    return (
                        getYearMonth(current) < getYearMonth(minDate) ||
                        getYearMonth(current) > getYearMonth(maxDate)
                    );

                default:
                    return !(current.diff(from, 'days') >= 0 && current.diff(from, 'days') < range);
            }
        }

        return false;
    };

    function disabledScheduleDate(current) {
        // Can not select days before today
        return current && current < moment().startOf('day');
    }

    function deleteInvoice() {
        setLoader({
            ...loader,
            delete: true
        })
        deleteI(selectedInvoice.id, (res) => {
            setLoader({
                ...loader,
                delete: false
            })
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
            } else {
                cancelModal();
                parentCallback({
                    isInvalid: false,
                    clientId: form.invoiceClient,
                    invoiceTypeId: form.invoiceType,
                    message: 'Invoice deleted successfully!'
                })
            }
        })
    }

    function confirmDelete() {
        confirm({
            title: 'Do you want to delete this invoice?',
            cancelText: "No",
            okText: "Yes",
            onOk() {
                deleteInvoice()
            },
            onCancel() { },
        });
    }

    useEffect(() => {
        if (globalAPICalled && selectedInvoice?.id && auditTable.pageSize != 0)
            getAuditHistory();
    }, [auditTable.currentPage, auditTable.pageSize, auditTable.sortInfo]);

    const auditTableChanges: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setAuditTable({
            ...auditTable,
            currentPage: pagination.current ?? 1,
            pageSize: pagination.pageSize ?? 10,
            totalPages: pagination.total ?? 100,
            sortInfo: sorter as SorterResult<any>
        })
    }

    useEffect(() => {
        if (globalAPICalled && selectedInvoice?.id && downloadFileTable.pageSize != 0)
            getSubmittedFileData();
    }, [downloadFileTable.currentPage, downloadFileTable.pageSize, downloadFileTable.sortInfo]);

    const downloadFileTableChanges: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setDownloadFileTable({
            ...downloadFileTable,
            currentPage: pagination.current ?? 1,
            pageSize: pagination.pageSize ?? 10,
            totalPages: pagination.total ?? 100,
            sortInfo: sorter as SorterResult<any>
        })
    }

    useEffect(() => {
        if (globalAPICalled && selectedInvoice?.id && uploadFileTable.pageSize != 0)
            getUploadFileData();
    }, [uploadFileTable.currentPage, uploadFileTable.pageSize, uploadFileTable.sortInfo]);

    const uploadFileTableChanges: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setUploadFileTable({
            ...uploadFileTable,
            currentPage: pagination.current ?? 1,
            pageSize: pagination.pageSize ?? 10,
            totalPages: pagination.total ?? 100,
            sortInfo: sorter as SorterResult<any>
        })
    }

    useEffect(() => {
        if (globalAPICalled && selectedInvoice?.id && downloadDataTable.pageSize != 0)
            getDownloadFileData();
    }, [downloadDataTable.currentPage, downloadDataTable.pageSize, downloadDataTable.sortInfo]);

    const downloadDataTableChanges: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setDownloadDataTable({
            ...downloadDataTable,
            currentPage: pagination.current ?? 1,
            pageSize: pagination.pageSize ?? 10,
            totalPages: pagination.total ?? 100,
            sortInfo: sorter as SorterResult<any>
        })
    }

    function changeStatus() {
        getStatusById(selectedInvoice.id, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                });
                setStatusForm({
                    visible: true,
                    status: form?.status,
                    note: '',
                    statusList: statusList
                })
            } else {
                setStatusForm({
                    visible: true,
                    status: res?.invoiceStatusId.toString(),
                    note: res?.note,
                    statusList: statusList.filter((x) => (x.clientId == form.invoiceClient && x.invoiceTypeId == form.invoiceType && res.statusIds.split(',').map((y) => parseInt(y)).includes(x.id)))
                })
            }
        })
        
    }

    function saveStatus() {
        setLoader({
            ...loader,
            status: true
        })

        changeInvoiceStatus({
            Id: selectedInvoice?.id,
            statusId: statusForm.status,
            Note: statusForm.note
        }, (res) => {
            setLoader({
                ...loader,
                status: false
            })
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
            } else {
                getAuditHistory();
                getInvoiceData();
                setStatusForm({ ...statusForm, visible: false });
                parentCallback({
                    isInvalid: false,
                    showMessage: true,
                    message: 'Status changed successfully!'
                });
            }
        })
    }

    function regenerateInvoice() {
        setLoader({
            ...loader,
            genarate: true
        })

        changeInvoiceStatus({
            Id: selectedInvoice?.id,
            statusId: invoiceInfo.regenerateStatusId,
            Note: ''
        }, (res) => {
            setLoader({
                ...loader,
                genarate: false
            })
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
            } else {
                getAuditHistory();
                getInvoiceData();
                parentCallback({
                    isInvalid: false,
                    showMessage: true,
                    message: 'Invoice Generation Process is in Queue!'
                });
            }
        })
    }

    function fileUploaded({ file, fileList }) {
        if (file?.status == "removed") {
            setFileUploadForm({
                ...fileUploadForm,
                file: null,
                fileList:[]
            });
            return;
        }
        const allowedTypes = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
            "application/pdf", // .pdf
            "text/csv", // .csv
            "text/plain", // .txt
            "image/png", // .png
        ];

        if (!allowedTypes.includes(file.type)) {
            message.error(`${file.name} is not an allowed file type.`);
            setFileUploadForm({
                ...fileUploadForm,
                fileList: []
            });
            return Upload.LIST_IGNORE;
        } else {
            setFileUploadForm({
                ...fileUploadForm,
                file: file.originFileObj || file,
                fileList: fileList
            });
        }
    }

    function saveFile() {
        if (!fileUploadForm.file) {
            parentCallback({
                isInvalid: true,
                errorMessage: "No file selected. Please choose a file to upload."
            })
            return;
        }
        setFileUploadForm({ ...fileUploadForm, loader: true })
        uploadFile({ id: selectedInvoice?.id, note: fileUploadForm.note, file: fileUploadForm.file }, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                });
                setFileUploadForm({ ...fileUploadForm, loader: false })
            } else {
                parentCallback({
                    isInvalid: false,
                    showMessage: true,
                    message: "File uploaded!!"
                });
                if (uploadFileTable.currentPage == 1) {
                    getUploadFileData();
                } else {
                    setUploadFileTable({
                        ...uploadFileTable, currentPage: 1
                    })
                }
                setFileUploadForm({ ...fileUploadForm, note: '', show: false, loader: false, file: null, fileList:[] })
            }
        })
    }

    function downloadFile(e, res) {
        // Create a Blob URL
        const blob = new Blob([res.data], { type: res.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);

        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        const fileName = e?.fileName ?? "file.txt";// Change the filename and extension as needed
        link.setAttribute("download", fileName); 
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link?.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
    function downloadSelectedFile(e) {
        setUploadFiles((x: any) => x.map((rec: any) => rec.id == e.id ? { ...e, downloading: true } : rec));
        downloadUploadedFile(e.id, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                });
            } else {
                downloadFile(e, res);
                parentCallback({
                    isInvalid: false,
                    showMessage: true,
                    message: "File downloaded!!"
                });
            }
            setTimeout(() => {
                setUploadFiles((x: any) => x.map((rec: any) => rec.id == e.id ? { ...e, downloading: false } : rec));
            }, 500)
        })
    }

    function downloadSelectedDataFile(e) {
        setGeneratedData((x: any) => x.map((rec: any) => rec.id == e.id ? { ...e, downloading: true } : rec));
        downloadRawDataFile(e.id, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                });
            } else {
                downloadFile(e, res);
                parentCallback({
                    isInvalid: false,
                    showMessage: true,
                    message: "File downloaded!!"
                });
            }
            setTimeout(() => {
                setGeneratedData((x: any) => x.map((rec: any) => rec.id == e.id ? { ...e, downloading: false } : rec));
            }, 500)
        })
    }

    function generateDataFile() {
        setLoader({
            ...loader,
            generateData: true
        })
        generateRawDataFile(selectedInvoice?.id, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                });
            } else {
                parentCallback({
                    isInvalid: false,
                    showMessage: true,
                    message: "File generation is in progress!!"
                });
                if (downloadDataTable.currentPage == 1) {
                    getDownloadFileData();
                } else {
                    setDownloadDataTable({
                        ...uploadFileTable, currentPage: 1
                    })
                }
                getInvoicePermissions();
            }
            setLoader({
                ...loader,
                generateData: false
            })
        })
    }

    function downloadSelectedSubmittedFile() {
        setLoader({
            ...loader,
            submitFile: true
        })
        downloadSubmittedFile(selectedInvoice?.id, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                });
            } else {
                downloadFile(downloadFiles.length ? downloadFiles[0] : null, res);
                parentCallback({
                    isInvalid: false,
                    showMessage: true,
                    message: "File downloaded!!"
                });
            }
            if (downloadFileTable.currentPage == 1) {
                getSubmittedFileData();
            } else {
                setDownloadFileTable({
                    ...downloadFileTable, currentPage: 1
                })
            }
            setLoader({
                ...loader,
                submitFile: false
            })
        })
    }

    return (
        <div style={{ paddingTop:'15px' }}>
            <Modal title="Upload File" open={fileUploadForm.show}
                afterClose={() => setFileUploadForm({ ...fileUploadForm, show: false, note: '', file: null, fileList:[] })}
                onCancel={() => setFileUploadForm({ ...fileUploadForm, show: false, note: '', file: null, fileList: [] })}
                onOk={() => saveFile()}
                confirmLoading={fileUploadForm.loader}
                width={500}
                maskClosable={false}
                className="customModal"
            >
                <div>
                    <Row style={{ paddingBottom: '10px' }}>
                        <Col span={6}>
                            File
                        </Col>
                        <Col span={18}>
                            <Upload maxCount={1} fileList={fileUploadForm.fileList } onChange={fileUploaded} beforeUpload={() => false} accept=".xlsx,.pdf,.csv,.txt,.png">
                                <Button>
                                    <UploadOutlined /> Click to Upload
                                </Button>
                            </Upload>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            Note
                        </Col>
                        <Col span={18}>
                            <TextArea
                                placeholder="Enter Note"
                                autoSize={{ minRows: 3, maxRows: 6 }}
                                value={fileUploadForm.note}
                                onChange={(e) => setFileUploadForm({ ...fileUploadForm, note: e.target.value })}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Card styles={{ body: { padding: '15px' } }} loading={loading}>
                {
                    //action != 'edit' &&
                    <p
                        style={{
                            fontSize: 14,
                            color: 'rgba(0, 0, 0, 0.85)',
                            marginTop: '0px',
                            fontWeight: 500,
                        }}
                    >
                        Invoice Information
                    </p>
                }
                {
                    (action != 'edit' || editPermissions?.canEdit) &&
                    <div>
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={6} style={{ paddingRight: '0.5rem' }}>
                                <label>Client</label>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select a Client"
                                    onChange={(e) => clientSelected(e)}
                                    status={modalSubmitted && !form.invoiceClient ? 'error' : ''}
                                    value={form.invoiceClient}
                                    disabled={action == 'edit'}
                                >
                                    {clientList && clientList.map(item => (
                                        <Option key={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={6} style={{ paddingRight: '0.5rem' }}>
                                <label>Invoice Type</label>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Invoice Type"
                                    onChange={(e) => getInvoiceDetails(e)}
                                    value={form.invoiceType}
                                    disabled={!form.invoiceClient || action == 'edit'}
                                    status={modalSubmitted && !form.invoiceType ? 'error' : ''}
                                >
                                    {invoiceTypes && invoiceTypes.map(item => (
                                        <Option key={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={6} style={{ paddingRight: '0.5rem' }}>
                                <label>Invoice #</label>
                                <Input value={form.invoiceNumber} status={modalSubmitted && !form.invoiceNumber ? 'error' : ''} disabled={!form.invoiceClient || !form.invoiceType} placeholder="Invoice #" style={{ width: '100%' }} allowClear
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            invoiceNumber: e?.target?.value
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={6} style={{ paddingRight: '0.5rem' }}>
                                <label>Invoice Date</label>
                                <DatePicker placeholder='Invoice Date' status={modalSubmitted && !form.invoiceDate ? 'error' : ''} disabledDate={disableInvoiceDates} value={form.invoiceDate} format="MM/DD/YYYY" disabled={!form.invoiceClient || !form.invoiceType} style={{ width: '100%' }}
                                    onChange={(date: any) => {
                                        setForm({
                                            ...form,
                                            invoiceDate: date ? date : ''
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={6}>
                                <Checkbox checked={form.includePrevious} disabled={!form.invoiceClient || !form.invoiceType}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            includePrevious: e.target.checked
                                        })
                                    }}>
                                    Include Non-billable from Previous Invoices
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={12} style={{ paddingRight: '0.5rem' }}>
                                <label>DOS Range</label>
                                <RangePicker style={{ width: '100%' }}
                                    format="MM/DD/YYYY"
                                    value={[form.startDate, form.endDate]}
                                    status={modalSubmitted && !(form.startDate && form.endDate) ? 'error' : ''}
                                    disabled={!form.invoiceClient || !form.invoiceType}
                                    disabledDate={disabledRangeDate}
                                    onChange={(dates: any) => {
                                        if (dates) {
                                            setForm({
                                                ...form,
                                                startDate: dates[0],
                                                endDate: dates[1]
                                            })
                                        } else {
                                            setForm({
                                                ...form,
                                                startDate: '',
                                                endDate: ''
                                            })
                                        }
                                    }}
                                />
                            </Col>
                            <Col span={6} style={{ paddingRight: '0.5rem' }}>
                                <label>Schedule Date & Time</label>
                                <DatePicker
                                    format="MM/DD/YYYY HH:mm:ss"
                                    showTime={true}
                                    placeholder='Date'
                                    value={form.scheduleDateTime}
                                    style={{ width: '100%' }}
                                    disabledDate={disabledScheduleDate}
                                    disabled={!form.invoiceClient || !form.invoiceType}
                                    onChange={(date: any) => {
                                        if (date) {
                                            setForm({
                                                ...form,
                                                scheduleDateTime: date
                                            })
                                        } else {
                                            setForm({
                                                ...form,
                                                scheduleDateTime: ''
                                            })
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                            <Flex justify="end">
                                <Space>
                                    <Button key="3" onClick={e => cancelModal(e)} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                                        Cancel
                                    </Button>
                                    {
                                        ((action != 'edit' && userAccess?.canAdd) || (action == 'edit' && editPermissions?.canEdit)) &&
                                        <Button key="1" onClick={e => saveInvoice(false)} style={{ backgroundColor: "#ff4d4f", color: "#fff" }}>Save</Button>
                                    }
                                    {
                                        form?.invoiceType != '2' && ((action != 'edit' && userAccess?.canGenerate) || (action == 'edit' && editPermissions?.canSaveAndGenerate)) && <Button key="2" onClick={e => confirmGenerate()} style={{ backgroundColor: "#52c41a", color: "#fff" }}>Save & Generate</Button>
                                    }
                                    {
                                        action == 'edit' && editPermissions?.canDelete &&
                                        <Button key="4" onClick={e => confirmDelete()} style={{ backgroundColor: "blue", color: "#fff" }}>Delete</Button>
                                    }
                                </Space>
                            </Flex>
                    </div>
                    
                }
                {
                    action == 'edit' && !editPermissions?.canEdit &&
                    <div>
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={4} style={{ paddingRight: '0.5rem' }}>
                                <label>Client</label>
                                <br />
                                {form.clientName}
                            </Col>
                            <Col span={4} style={{ paddingRight: '0.5rem' }}>
                                <label>Invoice Type</label>
                                <br />
                                {form.invoiceTypeName}
                            </Col>
                            <Col span={4} style={{ paddingRight: '0.5rem' }}>
                                <label>Invoice #</label><br/>
                                {form.invoiceNumber}
                            </Col>
                            <Col span={4} style={{ paddingRight: '0.5rem' }}>
                                <label>Invoice Date</label>
                                <br />
                                {form.invoiceDate? dayjs(form.invoiceDate).format('MM/DD/YYYY'):''}
                            </Col>
                            <Col span={8}>
                                <label>Include Non-billable from Previous Invoices</label>
                                <br />
                               {form.includePrevious ? 'Yes':'No'}
                            </Col>
                        </Row>
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={4} style={{ paddingRight: '0.5rem' }}>
                                <label>From DOS</label>
                                <br />
                                {form.startDate? dayjs(form.startDate).format('MM/DD/YYYY'):''}
                            </Col>
                            <Col span={4} style={{ paddingRight: '0.5rem' }}>
                                <label>To DOS</label>
                                <br />
                                {form.endDate? dayjs(form.endDate).format('MM/DD/YYYY'):''}
                            </Col>
                            <Col span={4} style={{ paddingRight: '0.5rem' }}>
                                <label>Schedule Date & Time</label>
                                <br />
                                {form.scheduleDateTime? dayjs(form.scheduleDateTime).format('MM/DD/YYYY HH:mm A'):'-'}
                            </Col>
                            <Col span={4} style={{ paddingRight: '0.5rem' }}>
                                <label>Status</label>
                                <br />
                                {form.status}
                            </Col>
                        </Row>
                        <Flex justify="end">
                            <Space>
                                {
                                    editPermissions?.canChangeStatus && 
                                    <Button key="changeStatusBtn" disabled={statusForm.visible} onClick={e => changeStatus()} style={{ backgroundColor: "blue", color: "#fff" }}>Change Status</Button>
                                }
                                {
                                    editPermissions?.canRegenerate &&
                                    <Button key="regenerateBtn" onClick={e => regenerateInvoice()} loading={loader.genarate} style={{ backgroundColor: "#52c41a", color: "#fff" }}>Re-generate</Button>
                                }
                            </Space>
                        </Flex>
                    </div>
                }
                
            </Card>
            {
                action == 'edit' &&
                <div style={{ padding: "1rem", background: '#eff1f5' }}></div>
            }
            {
                action == 'edit' && statusForm.visible &&
                <div style={{ background: "#eff1f5" }}>
                        <Card styles={{ body: { padding: '15px' } }}>
                            <p
                                style={{
                                    fontSize: 14,
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    marginTop: '0px',
                                    fontWeight: 500,
                                }}
                            >
                                Change Status
                            </p>
                        <Row style={{ paddingBottom: '0.5rem'}}>
                            <Col span={8}>
                                <label>Status</label>
                                <Select
                                    style={{ width: '95%' }}
                                    placeholder="Select a Status"
                                    onChange={(e) => setStatusForm({ ...statusForm, status: e, note:'' })}
                                    value={statusForm.status}
                                    status={!statusForm.status ? 'error' : ''}
                                    >
                                    {statusForm.statusList && statusForm.statusList.map(item => (
                                        <Option key={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={10}>
                                <label>Note</label>
                                <TextArea
                                    placeholder="Enter Note"
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                    value={statusForm.note}
                                    maxLength={300}
                                    onChange={(e) => setStatusForm({ ...statusForm, note: e.target.value })}
                                />
                            </Col>
                        </Row>
                        <Flex justify="end">
                            <Space>
                                <Button key="saveNote" loading={loader.status} disabled={!statusForm.status} onClick={e => saveStatus()} style={{ backgroundColor: "blue", color: "#fff" }}>Save</Button>
                                <Button key="cancelNote" onClick={e => setStatusForm({ ...statusForm, visible: false })} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }}>Cancel</Button>
                            </Space>
                        </Flex>
                    </Card>
                    <div style={{ padding: "1rem" }}></div>
                </div>
            }
            {
                action == 'edit' && !editPermissions?.canEdit && additionalInfo?.length > 0 && 
                <div style={{ background: "#eff1f5" }}>
                    <Card styles={{ body: { padding: '15px' } }}>
                        <p
                            style={{
                                fontSize: 14,
                                color: 'rgba(0, 0, 0, 0.85)',
                                marginTop: '0px',
                                fontWeight: 500,
                            }}
                        >
                            Additional Information
                        </p>
                        <Row type="flex" style={{ background: '#fff' }}>
                            {
                                additionalInfo && additionalInfo.map((item, index) => (
                                <Col key={index} span={4} style={{ paddingRight: '0.5rem', paddingBottom: '0.5rem', wordBreak: "break-all" }}>
                                    <label key={'label' + index}>{item.caption}</label>
                                    <br />
                                    {item.value}
                                </Col>
                            ))}
                        </Row>
                    </Card>
                    <div style={{ padding: "1rem" }}></div>
                </div>
            }
            {
                action == 'edit' &&
                <Flex style={{ padding: "1rem", background: '#fff' }}>
                    <Tabs type="card" style={{ width:'100%' }}>
                        <TabPane tab="Audit History" key="auditHistoryTab">
                            {/*<ColumnDropdown columns={auditColumns} menuVisible={auditTable.menuVisible} position={position} />*/}
                            <Table style={{ overflowY: "auto", height: "auto" }}
                                dataSource={auditHistory}
                                pagination={{
                                    defaultCurrent: 1,
                                    current: auditTable.currentPage,
                                    pageSize: auditTable.pageSize,
                                    total: auditTable.totalPages,
                                    showTotal: (total, range) => `${range[0] || 0}-${range[1] || 1} of ${total} items`,
                                    showSizeChanger: true,
                                }}
                                columns={auditColumns}
                                onChange={auditTableChanges}
                                rowKey="date"
                                loading={{ indicator: <LoaderSpinner size={24} topMargin="10px" />, spinning: auditTable.loading }}
                                onHeaderRow={(columns, index) => {
                                    return {
                                        onContextMenu: (event) => {
                                            event.preventDefault();
                                            if (!auditTable.menuVisible) {
                                                document.addEventListener(`click`, function onClickOutside() {
                                                    setAuditTable({
                                                        ...auditTable,
                                                        menuVisible: false
                                                    });
                                                    document.removeEventListener(`click`, onClickOutside);
                                                });
                                            }
                                            setAuditTable({
                                                ...auditTable,
                                                menuVisible: true
                                            });
                                            setPosition({ x: event.clientX, y: event.clientY });
                                        }
                                    };
                                }}
                            />
                        </TabPane>
                        {
                            editPermissions?.isDownloadTabVisible && 
                            <TabPane tab="Download Generated Data" key="downloadData">
                                {
                                    editPermissions?.canDownloadGeneratedData &&
                                        <div style={{ paddingBottom: '5px' }}>
                                            <Button key="generateFile" loading={loader.generateData} onClick={e => generateDataFile()} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }}>
                                            <PlusOutlined /> Generate File
                                        </Button>
                                    </div>
                                }
                                <Table style={{ overflowY: "auto", height: "auto" }}
                                    dataSource={generatedData}
                                    pagination={{
                                        defaultCurrent: 1,
                                        current: downloadDataTable.currentPage,
                                        pageSize: downloadDataTable.pageSize,
                                        total: downloadDataTable.totalPages,
                                        showTotal: (total, range) => `${range[0] || 0}-${range[1] || 1} of ${total} items`,
                                        showSizeChanger: true,
                                    }}
                                    columns={downloadDataColumns}
                                    onChange={downloadDataTableChanges}
                                    rowKey="id"
                                    loading={{ indicator: <LoaderSpinner size={24} topMargin="10px" />, spinning: downloadDataTable.loading }}
                                    onHeaderRow={(columns, index) => {
                                        return {
                                            onContextMenu: (event) => {
                                                event.preventDefault();
                                                if (!downloadDataTable.menuVisible) {
                                                    document.addEventListener(`click`, function onClickOutside() {
                                                        setDownloadDataTable({
                                                            ...downloadDataTable,
                                                            menuVisible: false
                                                        });
                                                        document.removeEventListener(`click`, onClickOutside);
                                                    });
                                                }
                                                setDownloadDataTable({
                                                    ...downloadDataTable,
                                                    menuVisible: true
                                                });
                                                setPosition({ x: event.clientX, y: event.clientY });
                                            }
                                        };
                                    }}
                                />
                            </TabPane>
                        }
                        <TabPane tab="Upload file" key="uploadFile">
                            <div style={{ paddingBottom:'5px' }}>
                                    <Button key="uploadNewFile" onClick={e => setFileUploadForm({ ...fileUploadForm, show: true, loader: false })} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }}>
                                    <PlusOutlined/> Upload File
                                </Button>
                            </div>
                            <Table style={{ overflowY: "auto", height: "auto" }}
                                dataSource={uploadFiles}
                                pagination={{
                                    defaultCurrent: 1,
                                    current: uploadFileTable.currentPage,
                                    pageSize: uploadFileTable.pageSize,
                                    total: uploadFileTable.totalPages,
                                    showTotal: (total, range) => `${range[0] || 0}-${range[1] || 1} of ${total} items`,
                                    showSizeChanger: true,
                                }}
                                columns={uploadFileColumns}
                                onChange={uploadFileTableChanges}
                                rowKey="id"
                                loading={{ indicator: <LoaderSpinner size={24} topMargin="10px" />, spinning: uploadFileTable.loading }}
                                onHeaderRow={(columns, index) => {
                                    return {
                                        onContextMenu: (event) => {
                                            event.preventDefault();
                                            if (!uploadFileTable.menuVisible) {
                                                document.addEventListener(`click`, function onClickOutside() {
                                                    setUploadFileTable({
                                                        ...uploadFileTable,
                                                        menuVisible: false
                                                    });
                                                    document.removeEventListener(`click`, onClickOutside);
                                                });
                                            }
                                            setUploadFileTable({
                                                ...uploadFileTable,
                                                menuVisible: true
                                            });
                                            setPosition({ x: event.clientX, y: event.clientY });
                                        }
                                    };
                                }}
                            />
                        </TabPane>
                        <TabPane tab="Download Submitted File" key="downloadFile">
                            {
                                editPermissions?.canDownloadSubmittedFile &&
                                <div style={{ paddingBottom: '5px' }}>
                                    <Button key="downloadSubmittedBtn" loading={loader.submitFile}  onClick={e => downloadSelectedSubmittedFile()} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }}>
                                        <PlusOutlined /> Download Submitted File
                                    </Button>
                                </div>
                            }
                            <Table style={{ overflowY: "auto", height: "auto" }}
                                dataSource={downloadFiles}
                                pagination={{
                                    defaultCurrent: 1,
                                    current: downloadFileTable.currentPage,
                                    pageSize: downloadFileTable.pageSize,
                                    total: downloadFileTable.totalPages,
                                    showTotal: (total, range) => `${range[0] || 0}-${range[1] || 1} of ${total} items`,
                                    showSizeChanger: true,
                                }}
                                columns={downloadFileColumns}
                                onChange={downloadFileTableChanges}
                                rowKey="id"
                                loading={{ indicator: <LoaderSpinner size={24} topMargin="10px" />, spinning: downloadFileTable.loading }}
                                onHeaderRow={(columns, index) => {
                                    return {
                                        onContextMenu: (event) => {
                                            event.preventDefault();
                                            if (!downloadFileTable.menuVisible) {
                                                document.addEventListener(`click`, function onClickOutside() {
                                                    setDownloadFileTable({
                                                        ...downloadFileTable,
                                                        menuVisible: false
                                                    });
                                                    document.removeEventListener(`click`, onClickOutside);
                                                });
                                            }
                                            setDownloadFileTable({
                                                ...downloadFileTable,
                                                menuVisible: true
                                            });
                                            setPosition({ x: event.clientX, y: event.clientY });
                                        }
                                    };
                                }}
                            />
                        </TabPane>
                    </Tabs>
                </Flex>
            }
    </div>
  )
}
