import { Button, Col, Divider, Flex, Input, InputNumber, Row, Select, Skeleton, Space } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { getCorrectionTriplegDetails, saveCorrectionTripLeg } from "../../service/httpsCalls";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;


export default function CorrectDetails({ selectedRec, dropdownLookup, bulkUpdate = false, disableSave=false, handleEvents }) {
    const [detailsSubmitted, setSubmitted] = useState(false);
    const [details, setDetails] = useState<any>({});
    const [saveLoader, setSaveLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (!bulkUpdate) {
            setIsLoading(true);
            getCorrectionTriplegDetails({
                clientId: selectedRec[0].clientId,
                clientInvoiceId: selectedRec[0].invoiceId,
                tripLegId: selectedRec[0].tripLegId
            }, (res) => {
                setIsLoading(false);
                if (res?.hasError) {
                    const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!';
                    handleEvents({
                        isInvalid: true,
                        errorMessage: errorMsg
                    })
                } else {
                    res.nonBillableReasonCode = res?.nonBillableReasonCode ? res?.nonBillableReasonCode.split(';') : null;
                    res.treatmentTypeId = res.treatmentTypeId ? res.treatmentTypeId.toString() : null;
                    res.levelOfServiceId = res.levelOfServiceId ? res.levelOfServiceId.toString() : null;
                    setDetails(res);
                }
            })
        }
    }, []);

    const clearData = () => {
        setDetails({});
        setSubmitted(false);
        handleEvents({
            close: true
        });
        selectedRec = [];
    }

    function validateData() {
        if (!(details.treatmentTypeId && details.miscInitiatives && details.memberId && details.mileage && details.levelOfServiceId && details.tripLegStatus && details.billable && (details.billable.toUpperCase() == 'YES' || (details.billable.toUpperCase() == 'NO' && details.nonBillableReasonCode?.length)) && details.authorizationNumber && details.doState && details.puState)) {
            return false;
        }
        return true;
    }

    function validateBulkUpdate() {
        if (!(details.note || details.miscInitiatives || details.treatmentTypeId || details.memberId || details.mileage || details.levelOfServiceId || details.tripLegStatus || (details.billable && (details.billable.toUpperCase() == 'YES' || (details.billable.toUpperCase() == 'NO' && details.nonBillableReasonCode?.length))) || details.authorizationNumber || details.doState || details.puState)) {
            return false;
        }
        return true
    }

    function getSaveObj() {
        let obj = {
            clientId: selectedRec[0].clientId,
            clientInvoiceSId: selectedRec[0].invoiceId,
            correctedTripLegs: [{
                authorizationNumber: details.authorizationNumber,
                billable: details.billable,
                levelOfServiceId: parseInt(details.levelOfServiceId),
                doState: details.doState,
                memberId: details.memberId,
                mileage: details.mileage,
                miscInitiatives: details.miscInitiatives,
                nonBillableReasonCode: details.nonBillableReasonCode?.length > 0 ? details.nonBillableReasonCode.join(';') : null,
                note: details.note,
                puState: details.puState,
                treatmentTypeId: parseInt(details.treatmentTypeId),
                tripLegId: details.tripLegId,
                tripLegStatus: details.tripLegStatus
            }]
        }
        return obj;
    }

    const save = () => {
        setSubmitted(true);
        if (validateData()) {
            setSaveLoader(true);
            saveCorrectionTripLeg(getSaveObj(), (res) => {
                setSaveLoader(false);
                if (res?.hasError) {
                    const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!';
                    handleEvents({
                        isInvalid: true,
                        errorMessage: errorMsg
                    })
                } else {
                    clearData();
                    handleEvents({
                        success: true,
                        showMessage: true,
                        message: 'Trip Leg Correction Details updated successfully!'
                    });
                }
            })
        }
    }

    const handleMileageChange = (inputValue) => {
        // Check if inputValue is valid (null if cleared)
        if (inputValue === null) {
            setDetails({
                ...details,
                mileage: null
            })
            return;
        }
        var valueString = inputValue.toString();
        var decimalIndex = valueString.indexOf('.');
        if (decimalIndex != -1) {
            valueString = valueString.length > 9 ? valueString.slice(0, 9) : valueString;
            if (valueString.length - decimalIndex > 2) {
                valueString = parseFloat(valueString.slice(0, (decimalIndex + 3)))
            } else {
                valueString = parseFloat(valueString);
            }
            setDetails({
                ...details,
                mileage: valueString
            })
        } else {
            setDetails({
                ...details,
                mileage: inputValue
            })
        }
        
    };

    const applyBulk = () => {
        if (validateBulkUpdate()) {
            handleEvents({
                applyChanges: true,
                data: details
            });
            reset();
        } else {
            if (details?.billable?.toUpperCase() == 'NO' && !details.nonBillableReasonCode?.length) {
                handleEvents({
                    isInvalid: true,
                    errorMessage: "Please select Non Billable Reason"
                });
            } else {
                handleEvents({
                    isInvalid: true,
                    errorMessage: "Atleast one detail needs to be updated"
                });
            }      
        }
    }

    const reset = () => {
        setDetails({})
    }
    return (
        <>
            <Skeleton loading={isLoading} active>
                <div className={ bulkUpdate?'bulk-update-container':'' }>
                    <Row style={{ paddingBottom: '5px', paddingTop: "0px" }}>
                        <Col span={bulkUpdate ? 24: 12}>
                            <label>Member Id</label>
                            <Input value={details?.memberId} maxLength={50} status={detailsSubmitted && !details?.memberId ? 'error' : ''} placeholder="Member Id" style={{ width: '95%' }}
                                allowClear={bulkUpdate}
                                onChange={(e) => {
                                    setDetails({
                                        ...details,
                                        memberId: e?.target?.value
                                    })
                                }}
                            />
                        </Col>
                        <Col span={bulkUpdate ? 24 : 12}>
                            <label>Misc Initiatives</label>
                            <Select
                                style={{ width: bulkUpdate ? '95%' : '100%' }}
                                value={details?.miscInitiatives}
                                placeholder="Select"
                                status={detailsSubmitted && !details?.miscInitiatives ? 'error' : ''}
                                allowClear={bulkUpdate }
                                onChange={(value) => {
                                    setDetails({
                                        ...details,
                                        miscInitiatives: value
                                    })
                                }}
                            >
                                <Option key="YES">Yes</Option>
                                <Option key="NO">No</Option>
                            </Select>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: '5px', paddingTop: "0px" }}>
                        <Col span={bulkUpdate ? 24 : 12}>
                            <label>Mileage</label>
                            <InputNumber<string> value={details?.mileage} maxLength={9} status={detailsSubmitted && !details?.mileage ? 'error' : ''} placeholder="Mileage" style={{ width: '95%' }}
                                step="0.01"
                                max="999999.99" // Maximum value allowed
                                min="0" // Minimum value allowed
                                onChange={handleMileageChange}
                            />
                        </Col>
                        <Col span={bulkUpdate ? 24 : 12}>
                            <label>Status</label>
                            <Select
                                style={{ width: bulkUpdate ? '95%' : '100%' }}
                                placeholder="Select Status"
                                allowClear={bulkUpdate}
                                status={detailsSubmitted && !details?.tripLegStatus ? 'error' : ''}
                                onChange={(value) => {
                                    setDetails({
                                        ...details,
                                        tripLegStatus: value
                                    })
                                }}
                                value={details?.tripLegStatus}
                            >
                                {dropdownLookup?.status && dropdownLookup?.status.map((item: any) => (
                                    <Option key={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: '5px', paddingTop: "0px" }}>
                        <Col span={bulkUpdate ? 24 : 12}>
                            <label>Level Of Service</label>
                            <Select
                                style={{ width: '95%' }}
                                placeholder="Select Level Of Service"
                                allowClear={bulkUpdate}
                                status={detailsSubmitted && !details?.levelOfServiceId ? 'error' : ''}
                                onChange={(value, option) => {
                                    setDetails({
                                        ...details,
                                        levelOfServiceId: value,
                                        levelOfService: option.children ?? null
                                    })
                                }}
                                value={details?.levelOfServiceId}
                            >
                                {dropdownLookup?.levelOfService && dropdownLookup?.levelOfService.map((item: any) => (
                                    <Option key={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={bulkUpdate ? 24 : 12}>
                            <label>Treatment Type</label>
                            <Select
                                style={{ width: bulkUpdate ? '95%' : '100%' }}
                                showSearch={true}
                                placeholder="Select Treatment Type"
                                allowClear={bulkUpdate}
                                status={detailsSubmitted && !details?.treatmentTypeId ? 'error' : ''}
                                onChange={(value, option) => {
                                    setDetails({
                                        ...details,
                                        treatmentTypeId: value,
                                        treatmentType: option.children ?? null
                                    })
                                }}
                                value={details?.treatmentTypeId}
                                filterOption={(input, option) =>
                                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {dropdownLookup?.treatmentType && dropdownLookup?.treatmentType.map((item: any) => (
                                    <Option key={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: '5px', paddingTop: "0px" }}>
                        <Col span={bulkUpdate ? 24 : 4}>
                            <label>Billable</label>
                            <Select
                                style={{ width: '95%' }}
                                placeholder="Select"
                                allowClear={bulkUpdate}
                                status={detailsSubmitted && !details?.billable ? 'error' : ''}
                                onChange={(value) => {
                                    if (value == "YES") {
                                        setDetails({
                                            ...details,
                                            billable: value,
                                            nonBillableReasonCode: null
                                        })
                                    } else {
                                        setDetails({
                                            ...details,
                                            billable: value
                                        })
                                    }
                                }}
                                value={details?.billable}
                            >
                                <Option key="YES">Yes</Option>
                                <Option key="NO">No</Option>
                            </Select>
                        </Col>
                        <Col span={bulkUpdate ? 24 : 20}>
                            <label>Non Billable Reason</label>
                            <Select
                                style={{ width: bulkUpdate ? '95%' : '100%' }}
                                mode="multiple"
                                placeholder="Select Reason"
                                allowClear={bulkUpdate}
                                status={(detailsSubmitted || bulkUpdate) && details?.billable?.toUpperCase() == 'NO' && !(details?.nonBillableReasonCode?.length > 0) ? 'error' : ''}
                                disabled={!details?.billable || details?.billable?.toUpperCase() == 'YES'}
                                onChange={(value, option) => {
                                    setDetails({
                                        ...details,
                                        nonBillableReasonCode: value,
                                        nonBillableReasonCodeDesc: option.map((x) => x.children).join(';')
                                    })
                                }}
                                value={details?.nonBillableReasonCode}
                                optionFilterProp="label"
                                showSearch
                                filterOption={(input, option) =>
                                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {dropdownLookup?.nonBillableReasons && dropdownLookup?.nonBillableReasons.map((item: any) => (
                                    <Option key={item.value} label={item.name}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: '5px', paddingTop: "0px" }}>
                        <Col span={bulkUpdate ? 24 : 12}>
                            <label>PU State</label>
                            <Select
                                style={{ width: '95%' }}
                                placeholder="Select PU State"
                                allowClear={bulkUpdate}
                                status={detailsSubmitted && !details?.puState ? 'error' : ''}
                                onChange={(value) => {
                                    setDetails({
                                        ...details,
                                        puState: value
                                    })
                                }}
                                value={details?.puState}
                            >
                                {dropdownLookup?.state && dropdownLookup?.state.map((item: any) => (
                                    <Option key={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={bulkUpdate ? 24 : 12}>
                            <label>DO State</label>
                            <Select
                                style={{ width: bulkUpdate ? '95%':'100%' }}
                                placeholder="Select DO State"
                                allowClear={bulkUpdate}
                                status={detailsSubmitted && !details?.doState ? 'error' : ''}
                                onChange={(value) => {
                                    setDetails({
                                        ...details,
                                        doState: value
                                    })
                                }}
                                value={details?.doState}
                            >
                                {dropdownLookup?.state && dropdownLookup?.state.map((item: any) => (
                                    <Option key={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: '5px', paddingTop: "0px" }}>
                        <Col span={bulkUpdate ? 24 : 12}>
                            <label>Authorization Number</label>
                            <Input value={details?.authorizationNumber} maxLength={50} status={detailsSubmitted && !details?.authorizationNumber ? 'error' : ''} placeholder="Authorization Number" style={{ width: '95%' }}
                                allowClear={bulkUpdate}
                                onChange={(e) => {
                                    setDetails({
                                        ...details,
                                        authorizationNumber: e?.target?.value
                                    })
                                }}
                            />
                        </Col>
                        <Col span={bulkUpdate ? 24 : 12}>
                            <label>Note</label>
                            <TextArea
                                placeholder="Enter Note"
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                value={details?.note}
                                maxLength={300}
                                allowClear={bulkUpdate}
                                style={{ width: bulkUpdate ? '95%' : '100%' }}
                                onChange={(e) => setDetails({ ...details, note: e.target.value })}
                            />
                        </Col>
                    </Row>
                </div>
                {
                    !bulkUpdate &&
                    <Flex justify="end" style={{ borderTop: "1px solid lightgray", marginTop: '0.5rem', paddingTop: '10px', marginRight: '-20px', marginLeft: '-20px' }}>
                        <Space>
                            <Button key="saveDetails" loading={saveLoader} onClick={save} type="primary">Save</Button>
                            <Button key="cancelDetails" onClick={clearData} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)', marginRight:'20px' }} type="primary">
                                Cancel
                            </Button>
                        </Space>
                    </Flex>
                }
                {
                    bulkUpdate &&
                    <Divider style={{ margin: '0px' }} />
                }
                {
                    bulkUpdate &&
                    <Flex justify='center' style={{ paddingBottom: "1rem", paddingTop: "1rem", background: '#fff' }} gap='small'>
                            <Button key="applyBulk" disabled={disableSave} onClick={applyBulk} style={{ background: '#1677ff', color: '#fff' }}>
                            Apply Changes
                        </Button>
                        <Button key="resetBulkDetails" onClick={reset} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }}>
                            Reset
                        </Button>
                    </Flex>
                }
            </Skeleton>
        </>
    )
}