import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Col, Row, Flex, TableProps, Table, Modal, Switch, Divider, TableColumnsType } from "antd";
import TextArea from "antd/es/input/TextArea";
import { MakeCorrectionColumns, createTableConfig } from "./tableConfig";
import Card from "antd/es/card/Card";
import { SorterResult, TableRowSelection } from "antd/es/table/interface";
import { LoaderSpinner } from '../../components/common/Loader';
import { getCorrectionLookups, getCorrectionTrips, saveCorrectionTripLeg } from "../../service/httpsCalls";
import { EditOutlined } from '@ant-design/icons';
import CorrectDetails from "./correctDetails";
import "./billing.scss";
import debounce from "lodash.debounce";

const { Option } = Select;
const { confirm } = Modal;

interface PaginationObject {
    totalPages: number,
    loading: boolean,
    pageSize: number,
    currentPage: number,
    sortInfo: SorterResult<any>,
    menuVisible: boolean
}

interface CorrectionLookup {
    id: number,
    name: string,
    value: string
}

interface DropdownLookup {
    nonBillableReasons: CorrectionLookup[],
    status: CorrectionLookup[],
    treatmentType: CorrectionLookup[],
    levelOfService: CorrectionLookup[],
    state: CorrectionLookup[]
}
export default function MakeCorrection({ clientList, invoiceInfo, parentCallback }) {
    const [client, setClient] = useState(invoiceInfo.clientId.toString());
    const [invoiceNum, setInvoiceNum] = useState(invoiceInfo.invoiceNumber);
    const [searchByType, setSearchByType] = useState<any>(null);
    const [searchTypeOptions, setSearchTypeOptions] = useState<CorrectionLookup[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [records, setRecords] = useState<any>([]);
    const [masterRecords, setMasterRecords] = useState<any>([]);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [correctionRec, setCorrectionRec] = useState<any>([]);
    const [dropdownOptions, setDropdownOptions] = useState<DropdownLookup>();
    const [showBulkUpdate, setBulkUpdate] = useState(false);
    const [selectedTripLegs, setSelectedTripLegs] = useState<React.Key[]>([]);
    const [bulkLoader, setBulkLoader] = useState(false);
    const [bulkDetailsUpdated, setUpdated] = useState(false);
    const widthForBulk = '250px';
    const tblRef: Parameters<typeof Table>[0]['ref'] = React.useRef(null);
    const [tableKey, setTableKey] = useState(0); // Key state to refresh table

    let singleUpdateTableColumns: TableColumnsType<any> = [{
            title: '',
        dataIndex: 'tripLegId',
        width: 110,
        fixed: 'right',
            render: (text, record) => (
                <Button onClick={() => openDetailModal(record)} style={{ backgroundColor: 'red', borderColor: 'red', color: '#fff' }} type="primary">
                    <EditOutlined />&nbsp;Correct
                </Button>
            ),
            hidden: showBulkUpdate
    }];
    const [tableColumns, setTableColumns] = useState([]);
    const [bulkColumnConfig, setBulkColumnConfig] = useState([]);
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });
    let paginationObject: PaginationObject = {
        totalPages: 0,
        loading: false,
        pageSize: 5,
        currentPage: 1,
        sortInfo: {},
        menuVisible: false
    }

    const [tablePagination, setTablePagination] = useState(paginationObject);
    const [modalTitle, setModalTitle] = useState("Correct Details");
    const tableChanges: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        if (extra.action == 'filter') {
            return;
        }
        setTablePagination({
            ...tablePagination,
            currentPage: pagination.current ?? 1,
            pageSize: pagination.pageSize ?? 10,
            totalPages: pagination.total ?? 100,
            sortInfo: sorter as SorterResult<any>
        })
    }

    useEffect(() => {
        if (submitted && tablePagination.pageSize != 0)
            handleSearch();
    }, [tablePagination.currentPage, tablePagination.pageSize, tablePagination.sortInfo]);

    useEffect(() => {
        getLookupData();
    }, [])

    const handleSearch = () => {
        setSubmitted(true);
        setSelectedTripLegs([]);
        if (!(client && invoiceNum && searchText && searchByType)) {
            return;
        }
        setTablePagination({
            ...tablePagination,
            loading: true
        });
        getCorrectionTrips({
            clientId: client,
            id: invoiceInfo.id,
            searchByType: searchByType,
            tripCsv: searchText,
            sortField: tablePagination.sortInfo.order ? tablePagination.sortInfo.field : "tripLegId",
            sortDescending: tablePagination.sortInfo.order == 'descend'
        }, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!';
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
                setTablePagination({
                    ...tablePagination,
                    loading: false,
                    totalPages: 1
                });
            } else {
                setRecords(res.items);
                setMasterRecords(JSON.parse(JSON.stringify(res.items)));
                setTablePagination({
                    ...tablePagination,
                    loading: false,
                    totalPages: Math.ceil(res.totalRecords / (tablePagination.pageSize / tablePagination.pageSize))
                });
                if (res.columnConfigs?.length) {
                    setBulkColumnConfig(res.columnConfigs)
            }
                if (res.columnConfigs && !tableColumns.length) {
                    let cols = createTableConfig(res.columnConfigs);
                    !showBulkUpdate && cols.push(singleUpdateTableColumns[0]);
                    setTableColumns(cols);
                }

                parentCallback({
                    updateDrawerConfig: true,
                    width: res.items?.length > 0 ? 1250 : 1000
                })
            }
        })
    };

    const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        // Regular expression to allow alphanumeric, [, ], and -
        const filteredValue = inputValue.replace(/[^a-zA-Z0-9,\-\s]/g, "");

        setSearchText(filteredValue);
    };

    const getLookupData = () => {
        getCorrectionLookups(invoiceInfo.clientId, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!';
                parentCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
            } else {
                setSearchTypeOptions(res.searchByType);
                setSearchByType(res.searchByType[0].value);
                setDropdownOptions({
                    levelOfService: res.levelOfService,
                    status: res.status,
                    nonBillableReasons: res.nonBillableReason,
                    treatmentType: res.treatmentType,
                    state: res.state
                })
            }
        })
    }

    const openDetailModal = (rec) => {
        let selectedRec: any[] = [];
        setCorrectionRec(selectedRec.concat([{ ...rec, invoiceId: invoiceInfo.id, clientId: client }]));
        setModalTitle(`Correct CTC Trip Id: ${rec.ctcTripId} :: DOS: ${rec.dateOfService}`)
        setShowDetailModal(true);
    }

    const hideDetailModal = () => {
        setShowDetailModal(false);
        setCorrectionRec([]);
        setModalTitle("Correct Details");
    }

    const handleEvents = (e) => {
        if (e.close) {
            hideDetailModal();
        } else if (e.isInvalid || e.success) {
            parentCallback(e);
            if (e.success) {
                hideDetailModal();
                handleSearch();
            }
        } else if (e.applyChanges) {
            let newRec = records;
            let detailsUpdated = false;
            setRecords([]);
            setTableKey((prevKey) => prevKey + 1); // Change table key to reset filters
            selectedTripLegs.forEach((x) => {
                const index = newRec.findIndex((rec) => rec.tripLegId == x);
                if (index != -1) {
                    const oldData = newRec[index].oldData ?? newRec[index];
                    let nonBillableReasonCodes = newRec[index].nonBillableReasonCode;
                    let reasonDecr = newRec[index].nonBillableReasonDescr ?? '';
                    if (e.data.billable) {
                        if (e.data.billable.toUpperCase() == 'YES') {
                            nonBillableReasonCodes = '';
                            reasonDecr = '';
                        } else if (e.data.nonBillableReasonCode?.length > 0) {
                            nonBillableReasonCodes = Array.from(new Set(nonBillableReasonCodes.split(';').concat(e.data.nonBillableReasonCode))).join(';');
                            reasonDecr = reasonDecr ? Array.from(new Set(reasonDecr.split(';').concat(e.data.nonBillableReasonCodeDesc.split(';')))).join(';') : e.data.nonBillableReasonCodeDesc;
                        }
                    }
                    newRec[index] = {
                        ...newRec[index],
                        authorizationNumber: !e.data.authorizationNumber ? newRec[index].authorizationNumber : e.data.authorizationNumber,
                        billable: !e.data.billable ? newRec[index].billable : e.data.billable,
                        levelOfServiceId: isNaN(parseInt(e.data.levelOfServiceId)) ? newRec[index].levelOfServiceId : parseInt(e.data.levelOfServiceId),
                        doState: !e.data.doState ? newRec[index].doState : e.data.doState,
                        memberId: !e.data.memberId ? newRec[index].memberId : e.data.memberId,
                        mileage: !e.data.mileage ? newRec[index].mileage : e.data.mileage,
                        miscInitiatives: !e.data.miscInitiatives ? newRec[index].miscInitiatives : e.data.miscInitiatives,
                        nonBillableReasonCode: nonBillableReasonCodes,
                        note: !e.data.note ? newRec[index].note : e.data.note,
                        puState: !e.data.puState ? newRec[index].puState : e.data.puState,
                        treatmentTypeId: isNaN(parseInt(e.data.treatmentTypeId)) ? newRec[index].treatmentTypeId : parseInt(e.data.treatmentTypeId),
                        tripLegStatus: !e.data.tripLegStatus ? newRec[index].tripLegStatus : e.data.tripLegStatus,
                        levelOfService: !e.data.levelOfService ? newRec[index].levelOfService : e.data.levelOfService,
                        treatmentType: !e.data.treatmentType ? newRec[index].treatmentType : e.data.treatmentType,
                        nonBillableReasonDescr: reasonDecr,
                        detailsUpdated: true,
                        oldData: oldData
                    }
                    detailsUpdated = true;
                }
            });
            setTimeout(() => {
                setRecords(newRec);
                setUpdated(detailsUpdated);
            },200)
        }
    }

    useEffect(() => {
        if (!showBulkUpdate) {
            setSelectedTripLegs([]);
            masterRecords?.length >= 1 ? cancelBulkUpdate() : '';
            setBulkLoader(false);
        }

        if (bulkColumnConfig?.length) {
            let cols = createTableConfig(bulkColumnConfig);
            !showBulkUpdate && cols.push(singleUpdateTableColumns[0]);
            setTableColumns(cols);
        }
    }, [showBulkUpdate])

    const toggleBulkUpdate = (e) => {
        if (showBulkUpdate && bulkDetailsUpdated) {
            confirm({
                title: 'This will revert the changes made. Do you want to proceed?',
                cancelText: "No",
                okText: "Yes",
                onOk() {
        setBulkUpdate((prev) => !prev);
                },
                onCancel() { },
            });
        } else {
            setBulkUpdate((prev) => !prev);
    }
    }

    const onSelectChange = (newSelectedTripKeys: React.Key[], selectedRows: any[]) => {
        setSelectedTripLegs(newSelectedTripKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys: selectedTripLegs,
        onChange: debounce(onSelectChange, 300),
    };
    function getBulkSaveObj() {
        let tripLegData: any = [];
        records.forEach((x) => {
            if (x.detailsUpdated) {
                const details = x;
                tripLegData.push({
                    authorizationNumber: details.authorizationNumber,
                    billable: details.billable,
                    levelOfServiceId: parseInt(details.levelOfServiceId),
                    doState: details.doState,
                    memberId: details.memberId,
                    mileage: details.mileage,
                    miscInitiatives: details.miscInitiatives,
                    nonBillableReasonCode: details.nonBillableReasonCode ?? null,
                    note: details.note,
                    puState: details.puState,
                    treatmentTypeId: parseInt(details.treatmentTypeId),
                    tripLegId: details.tripLegId,
                    tripLegStatus: details.tripLegStatus
                });
            }
        })
        const obj = {
            clientId: client,
            clientInvoiceSId: invoiceInfo.id,
            correctedTripLegs: tripLegData
        }
        return obj;
    }

    const saveBulkChanges = () => {
        setBulkLoader(true);
        saveCorrectionTripLeg(getBulkSaveObj(), (res) => {
            setBulkLoader(false);
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!';
                handleEvents({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
            } else {
                handleEvents({
                    success: true,
                    showMessage: true,
                    message: 'Corrections updated successfully!'
                });
                setSelectedTripLegs([]);
                setUpdated(false);
                setRecords([]);
                handleSearch();
            }
        })
        }

    function resetBulkData() {
        setTablePagination({
            ...tablePagination,
            loading: true
        });
        setRecords([]);
        setSelectedTripLegs([]);
        setUpdated(false);
        setTimeout(() => {
            setRecords(masterRecords);
            setTablePagination({
                ...tablePagination,
                loading: false
            });
        }, 500);
    }
    const cancelBulkUpdate = () => {
        if (showBulkUpdate && bulkDetailsUpdated) {
            confirm({
                title: 'Do you want to discard unsaved changes?',
                cancelText: "No",
                okText: "Yes",
                onOk() {
                    resetBulkData()
                },
                onCancel() { },
            });
        } else {
            resetBulkData();
        }
    }

    return (
        <>
            <Modal title={modalTitle} open={showDetailModal}
                afterClose={hideDetailModal}
                onCancel={hideDetailModal}
                width={800}
                maskClosable={false}
                className="noFooterCustomModal"
                destroyOnClose={true}
                footer={[]}
            >
                {
                    showDetailModal && correctionRec?.length >= 1 && <CorrectDetails selectedRec={correctionRec} dropdownLookup={dropdownOptions} handleEvents={handleEvents}  />
                }
            </Modal>
            <div className={records?.length > 0 ? 'parentDiv' : ''}>
                <div className={showBulkUpdate && selectedTripLegs.length >= 1 ? 'leftDiv' : ''} style={{ width: records?.length > 0 && !selectedTripLegs.length? '100%': '' }}>
            <div style={{ background: "#fff", padding: '15px' }}>
                <Row style={{
                    paddingBottom: '0.5rem', paddingTop: "0.5rem" }}>
                    <Col span={24}>
                        <Input
                            style={{ width: "100%" }}
                            allowClear
                            status={submitted && !searchText ? 'error' : ''}
                            placeholder={"Enter Comma separated value" + (searchTypeOptions?.length && searchByType ? " of " + searchTypeOptions.find((x) => x.value == searchByType)?.name : '')}
                            value={searchText}
                            onChange={handleSearchTextChange}
                                    disabled={bulkDetailsUpdated }
                            addonBefore={
                                <Select
                                    style={{ width: "120px" }}
                                            disabled={bulkDetailsUpdated}
                                    onChange={(value) => setSearchByType(value)}
                                    value={searchByType} >
                                    {searchTypeOptions && searchTypeOptions.map((item:any) => (
                                        <Option key={item.value}>{item.name}</Option>
                                    ))}
                                </Select>
                            }
                        />
                    </Col>
                </Row>
                        <Flex justify='end' style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem", alignItems: "center", background: '#fff' }} gap='small'>
                            Bulk Update : <Switch value={showBulkUpdate} checkedChildren="On" unCheckedChildren="Off" onClick={toggleBulkUpdate} disabled={bulkDetailsUpdated } />
                            <Button onClick={handleSearch} loading={tablePagination.loading} style={{ marginLeft: '5px' }} type="primary" disabled={bulkDetailsUpdated}>
                        Search
                    </Button>
                </Flex>
            </div>
                    <Card styles={{ body: { padding: '15px', paddingTop: '10px', height: "auto", maxHeight: records?.length > 0 ? "calc(100vh - 268px)" : undefined } }}>
                        {
                            showBulkUpdate && records?.length > 0 && 
                            <Flex justify='start' style={{ paddingBottom: "5px", paddingTop: "0", alignItems: "center", background: '#fff' }} gap='small'>
                                <div style={{ background: '#e6f4ff', padding: '0px, 5px', fontWeight: 500 }}>
                                    <span style={{ marginRight: '5px', marginLeft: '5px', borderRight: "1px solid #d4d2d2", paddingRight: "5px" }}>
                                        Total: {records.length}
                                    </span>
                                    <span style={{ marginRight: '5px'}}>
                                        Selected: {selectedTripLegs.length}
                                </span>
                                </div>
                            </Flex>
                        }
                        {
                            records?.length > 0 &&
                            <Table
                    scroll={{
                                    y: records?.length > 0 ? "calc(100vh - 290px)" : undefined,
                                    x: records?.length > 0 ? 1200 : undefined
                    }}
                                key={tableKey}
                                ref={tblRef}
                                virtual={true}
                                className={showBulkUpdate ? 'bulkUpdateView' : undefined}
                            rowSelection={showBulkUpdate ? rowSelection : undefined}
                    dataSource={records}
                    pagination={false}
                    columns={tableColumns}
                    onChange={tableChanges}
                            rowKey="tripLegId"
                                size="small"
                    loading={{ indicator: <LoaderSpinner size={24} topMargin="10px" />, spinning: tablePagination.loading }}
                    onHeaderRow={(columns, index) => {
                        return {
                            onContextMenu: (event) => {
                                event.preventDefault();
                                if (!tablePagination.menuVisible) {
                                    document.addEventListener(`click`, function onClickOutside() {
                                        setTablePagination({
                                            ...tablePagination,
                                            menuVisible: false
                                        });
                                        document.removeEventListener(`click`, onClickOutside);
                                    });
                                }
                                setTablePagination({
                                    ...tablePagination,
                                    menuVisible: true
                                });
                                setPosition({ x: event.clientX, y: event.clientY });
                            }
                        };
                    }}
                />
                        }
                        {
                            showBulkUpdate && records?.length>0 &&
                            <Flex justify='end' style={{ paddingTop: "1rem", background: '#fff' }} gap='small'>
                                <Button key="saveBulk" loading={bulkLoader} onClick={saveBulkChanges}  style={{ background: '#1677ff', color: '#fff' }} disabled={!bulkDetailsUpdated}>
                                    Save
                                </Button>
                                <Button key="cancelUpdate" onClick={cancelBulkUpdate} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }}>
                                    Cancel
                                </Button>
                            </Flex>
                        }
            </Card>
                </div>
                {
                    showBulkUpdate && selectedTripLegs.length > 0 &&
                    <div style={{ width: widthForBulk, margin: "0", paddingLeft: '5px', borderLeft: "1px solid lightgray" }}>
                            <h3 style={{ textTransform: 'uppercase', margin: '0px' }}>Bulk Update</h3>
                            <Divider style={{ marginTop: '0px', marginBottom:'10px' }} />
                            <CorrectDetails selectedRec={selectedTripLegs} dropdownLookup={dropdownOptions} bulkUpdate={true} handleEvents={handleEvents} disableSave={bulkLoader} />                            
                    </div>
                }
            </div>
        </>
    );
};